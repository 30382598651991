<template>
  <div id="cashOut">
    <!-- <div class="tabbar">
      <div class="bar-item" v-for="(item,index) in tabbar" :key="index" @click="changeBar(index)">
        <p :class="index === barIndex ? 'active' : ''">{{item}}</p>
        <div class="line" v-if="index === barIndex"></div>
      </div>
    </div>-->
    <div v-if="barIndex !== 1">
      <div class="title" v-if="barIndex === 1">选择银行卡</div>
      <div class="card-box" v-if="barIndex === 1" @click="cardShow = true">
        <img class="bank-logo" v-lazy="activeBank.bank_logo" alt />
        <p>{{activeBank.bank_name}}</p>
        <span>**** {{activeBank.card_numbe}}</span>
        <img class="arrow" src="@images/right-arrow.png" alt />
      </div>

      <div class="title">金额（元）</div>

      <div class="change-box">
        <div>
          <span>￥</span>
          <input @input="cashInput" v-model="cashNum" type="number" :placeholder="maxPlace" />
        </div>
        <p @click="allCash">全部</p>
      </div>

      <p class="title">备注：选填</p>

      <div class="area-box">
        <textarea
          v-model="reason"
          @input="reasonInput"
          placeholder="请填写相关备注说明，不超过56个字，选填"
        ></textarea>
        <p class="tips">{{reasonNum}}</p>
      </div>

      <div class="submit-btn" @click="submit">确认提现</div>
      <p class="tips">平台将从提现金额中直接扣取2%手续费，剩余金额会在1~3个工作日内自动提现至当前授权登录的微信零钱中，请注意查收。</p>

      <van-popup v-model="cardShow" position="bottom" round :style="{ height: '88* 3.75px' }">
        <div class="van-top">
          <img @click="cardShow = false" src="@images/close.png" alt />
          <p class="van-title">选择银行卡</p>
        </div>

        <div class="card-list">
          <div
            class="card-item"
            v-for="(item) in bankList"
            :key="item.id"
            @click="changeBankCard(item)"
          >
            <img class="logo" v-lazy="item.bank_logo" alt />
            <p>{{item.bank_name}}</p>
            <span>**** {{item.card_numbe}}</span>
            <img class="choice" src="@images/right.png" v-if="activeBank.id === item.id" alt />
          </div>
        </div>

        <div class="add-card" @click="$router.push({path: '/addCard'})">
          <img src="@images/add-card.png" alt />
          <p>添加银行卡</p>
        </div>

        <div class="choice-btn" @click="choiceCard">确认选择</div>
      </van-popup>
    </div>

    <div class="nothing-card" v-if="activeBank && barIndex === 1">
      <img src="@images/bank-card.png" alt />
      <p>请先绑定一张银行卡</p>
      <div class="bind-card">立即绑定</div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { cashOutInfo, myBankCard, submitCashOut } from "../api/requist";
export default {
  components: {},
  props: [],
  data() {
    return {
      reason: "",
      reasonNum: "0/56",
      cardShow: false,
      cashNum: "",
      max: 0,
      maxPlace: "当前最多可提现金额为0元",
      barIndex: 0,
      tabbar: ["提现到零钱", "提现到银行卡"],
      activeBank: {},
      bankList: []
    };
  },
  created() {},
  mounted() {
    this.getInfo();
    this.getCard();
  },
  watch: {},
  computed: {},
  methods: {
    reasonInput() {
      if (this.reason.length >= 56) {
        this.reason = this.reason.slice(0, 56);
      }
      this.reasonNum = this.reason.length + "/56";
    },
    changeBar(index) {
      this.barIndex = index;
      this.getInfo();
    },

    changeBankCard(item) {
      this.activeBank = item;
    },

    async getCard() {
      let formData = new FormData();
      formData.append("merch_id", localStorage.getItem("merch_id"));
      let res = await myBankCard(formData);
      if (res.code === 200) {
        this.bankList = res.data.MerchBankList;
      }
    },

    async getInfo() {
      let formData = new FormData();
      formData.append("merch_id", localStorage.getItem("merch_id"));
      formData.append("apply_type", this.barIndex + 1);
      let res = await cashOutInfo(formData);
      if (res.code === 200) {
        this.max = res.data.SettlementMoney;
        this.maxPlace = `当前最多可提现金额为${this.max}元`;
        if (res.data.BankInfo) {
          this.activeBank = res.data.BankInfo;
        }
      }
    },

    allCash() {
      this.cashNum = this.max;
    },
    cashInput() {
      if (this.cashNum > this.max) {
        this.cashNum = this.max;
      }
    },

    async submit() {
      if (!this.cashNum || this.cashNum === "0" || +this.cashNum <= 0) {
        return Toast("请输入提现金额");
      }
      console.log(this.cashNum);
      let formData = new FormData();
      formData.append("merch_id", localStorage.getItem("merch_id"));
      formData.append("apply_type", this.barIndex + 1);
      formData.append("operating_amount", this.cashNum);
      formData.append("bank_id", this.activeBank.id);
      formData.append("remark", this.reason);
      let res = await submitCashOut(formData);

      if (res.code === 200) {
        this.$router.push({
          path: "/cashResult",
          query: {
            type: 1
          }
        });
      } else {
        this.$router.push({
          path: "/cashResult",
          query: {
            type: 0
          }
        });
      }
    },
    choiceCard() {
      this.cardShow = false;
    }
  }
};
</script>
<style lang="scss" >
@import "../common/css/theme.scss";
#cashOut {
  padding: 3.2 * 3.75px 4.8 * 3.75px 0;
  box-sizing: border-box;
  min-height: 100vh;
  background: rgba(255, 255, 255, 1);

  .area-box {
    margin: 0 auto;
    width: calc(100vw - 16px * 2);
    height: 37.33 * 3.75px;
    background: rgba(245, 247, 250, 1);
    border-radius: 1 * 3.75px;
    padding: 3.2 * 3.75px;
    box-sizing: border-box;
    textarea {
      width: 100%;
      height: 21.33 * 3.75px;
      font-size: 3.73 * 3.75px;
      font-weight: 400;
      line-height: 4.27 * 3.75px;
      color: #303133;
      outline: none;
      border: none;
      resize: none;
      background: rgba(245, 247, 250, 1);
      color: #909399;
      font-size: 3.73 * 3.75px;
      &::placeholder {
        color: #909399;
        font-size: 3.73 * 3.75px;
      }
    }

    .tips {
      text-align: right;
      margin-top: 5.07 * 3.75px;
      font-size: 3.2 * 3.75px;
      font-weight: 400;
      line-height: 4.27 * 3.75px;
      color: rgba(48, 49, 51, 1);
    }
  }

  .tabbar {
    display: flex;
    .bar-item {
      margin-right: 3.2 * 3.75px;
      p {
        font-size: 3.73 * 3.75px;
        font-weight: 400;
        line-height: 5.33 * 3.75px;
        color: rgba(96, 98, 102, 1);
        &.active {
          font-size: 4.27 * 3.75px;
          font-weight: bold;
          color: rgba(48, 49, 51, 1);
        }
      }

      .line {
        margin: 0.8 * 3.75px auto;
        width: 16 * 3.75px;
        height: 1 * 3.75px;
        background-color: rgba(48, 49, 51, 1);
        border-radius: 4px;
      }
    }
  }

  .title {
    font-size: 3.73 * 3.75px;
    color: rgba(96, 98, 102, 1);
    text-align: left;
    margin-top: 6.4 * 3.75px;
    margin-bottom: 3.2 * 3.75px;
  }

  .change-box {
    width: 100%;
    height: 13.07 * 3.75px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(240, 242, 245, 1);
    border-radius: 1 * 3.75px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3.2 * 3.75px;
    div {
      display: flex;
      align-items: center;
    }
    span {
      margin-right: 0.8;
      font-size: 4.8 * 3.75px;
      font-weight: bold;
      color: rgba(48, 49, 51, 1);
    }

    input {
      width: 56.53 * 3.75px;
      font-size: 3.73 * 3.75px;
      color: #301333;
      outline: none;
      border: none;
      &::placeholder {
        font-size: 3.73 * 3.75px;
        color: rgba(192, 196, 204, 1);
      }
    }

    p {
      font-size: 3.73 * 3.75px;
      color: rgba(48, 49, 51, 1);
    }
  }

  .card-box {
    width: 90.4 * 3.75px;
    height: 13.07 * 3.75px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(240, 242, 245, 1);
    border-radius: 1 * 3.75px;
    box-sizing: border-box;
    padding: 0 3.2 * 3.75px;
    display: flex;
    align-items: center;
    .bank-logo {
      height: 7.73 * 3.75px;
      width: 7.73 * 3.75px;
      margin-right: 1.6 * 3.75px;
    }

    p {
      font-size: 3.73 * 3.75px;
      color: rgba(48, 49, 51, 1);
      margin-right: 1.6 * 3.75px;
    }

    span {
      color: rgba(144, 147, 153, 1);
      font-size: 3.73 * 3.75px;
      margin-right: 38.67 * 3.75px;
    }

    .arrow {
      width: 1.6 * 3.75px;
      height: 3.2 * 3.75px;
    }
  }

  .submit-btn {
    margin: 6.4 * 3.75px auto 2.13 * 3.75px;
    font-size: 4.27 * 3.75px;
    line-height: 12.27 * 3.75px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    width: 100%;
    height: 12.27 * 3.75px;
    background: $theme-color;
    border-radius: 1 * 3.75px;
  }

  .tips {
    font-size: 3.2 * 3.75px;
    line-height: 4.27 * 3.75px;
    color: rgba(192, 196, 204, 1);
    text-align: left;
  }

  .van-popup {
    .van-top {
      display: flex;
      align-items: center;
      padding: 0 4.8 * 3.75px;
      height: 14.93 * 3.75px;
      img {
        height: 3.73 * 3.75px;
        width: 3.73 * 3.75px;
        margin-right: 32.8 * 3.75px;
      }
      .van-title {
        font-size: 3.73 * 3.75px;
        color: rgba(96, 98, 102, 1);
      }
    }

    .card-list {
      height: 42.4 * 3.75px;
      overflow: scroll;
      .card-item {
        height: 14.13 * 3.75px;
        display: flex;
        align-items: center;
        padding: 0 4.8 * 3.75px;
        box-sizing: border-box;
        border-top: 1px solid rgba(240, 242, 245, 1);
        &:last-child {
          border-bottom: 1px solid rgba(240, 242, 245, 1);
        }
        .logo {
          height: 7.73 * 3.75px;
          width: 7.73 * 3.75px;
          margin-right: 1.6 * 3.75px;
        }

        p {
          font-size: 3.73 * 3.75px;
          color: rgba(48, 49, 51, 1);
        }

        span {
          font-size: 3.73 * 3.75px;
          color: rgba(144, 147, 153, 1);
          margin-right: 42.93 * 3.75px;
        }

        .choice {
          width: 4.27 * 3.75px;
          height: 4.27 * 3.75px;
        }
      }
    }

    .add-card {
      padding: 0 4.8 * 3.75px;
      box-sizing: border-box;
      display: flex;
      height: 15.7 * 3.75px;
      align-items: center;
      img {
        width: 7.73 * 3.75px;
        height: 7.73 * 3.75px;
        margin-right: 1.6 * 3.75px;
      }

      p {
        font-size: 3.73 * 3.75px;
        color: rgba(48, 49, 51, 1);
      }
    }

    .choice-btn {
      width: 73.07 * 3.75px;
      height: 10.13 * 3.75px;
      background: $theme-color;
      margin: 0 auto;
      font-size: 3.73 * 3.75px;
      text-align: center;
      line-height: 10.13 * 3.75px;
      color: rgba(255, 255, 255, 1);
      border-radius: 6 * 3.75px;
    }
  }
  .nothing-card {
    img {
      height: 37.33 * 3.75px;
      margin: 26.66 * 3.75px auto 0;
      width: 37.33 * 3.75px;
      display: block;
    }

    p {
      color: #c0ccc4;
      text-align: center;
      font-size: 3.73 * 3.75px;
      margin: 2.4 * 3.75px auto 7.2 * 3.75px;
    }

    .bind-card {
      width: 90.4 * 3.75px;
      height: 12.26 * 3.75px;
      background: $theme-color;
      border-radius: 4px;
      font-size: 4.26 * 3.75px;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      line-height: 12.26 * 3.75px;
    }
  }
}
</style>
